.nunito-light {
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
  }
  
  .nunito-regular {
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }
  
  .nunito-semibold {
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
  }
  
  .nunito-bold {
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
  }
  
  .nunito-extrabold {
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
  }
  
  .nunito-black {
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
  }
  
  .login-container {
    display: flex;
    height: 100vh;
    padding-bottom: 0; /* Remove bottom padding to use full height */
  }
  
  .sidebar {
    width: 20%; /* Set to 1/5th of the screen */
    min-width: 200px; /* Ensure a minimum width for very large screens */
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #09ACE0; /* Moved from .light-theme .sidebar */
  }
  
  .logo {
    width: 100%; /* Adjust logo size relative to sidebar */
    max-width: 180px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .login-form-container {
    width: 80%; /* Take up the rest of the space */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    box-sizing: border-box;
  }
  
  .center-logo {
    width: 140px;
    height: 140px;
    margin-bottom: 20px;
  }
  
  .welcome-text {
    font-size: 32px;
    margin-bottom: 40px;
    color: #09ACE0;
  }
  
  .login-form {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .input-group {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
  }
  
  .input-label {
    display: block;
    margin-bottom: 10px;
    color: #09ACE0;
    font-size: 24px;
    font-weight: 600;
  }
  
  .input {
    width: 100%;
    padding: 12px 20px;
    border-radius: 40px;
    border: 2px solid #09ACE0;
    color: #00A3E0;
    outline: none;
    font-size: 18px;
  }
  
  .input::placeholder {
    color: #09ACE0;
    opacity: 0.7;
  }
  
  .eye-icon {
    position: absolute;
    right: 20px;
    top: 65%;
    transform: translateY(-50%);
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .eye-icon i {
    color: #09ACE0;
    font-size: 24px;
  }
  
  .button {
    width: 100%;
    padding: 12px;
    color: #FFFFFF;
    background-color: #09ACE0;
    border: none;
    border-radius: 33px;
    cursor: pointer;
    margin-top: 66px;
    font-size: 24px;
    font-weight: 600;
  }
  
  .forgot-password {
    font-size: 16px;
    color: #09ACE0;
    text-decoration: none;
    display: block;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
  .forgot-password:hover {
    text-decoration: underline;
  }
  
  .theme-toggle {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
  }
  
  .light-theme .sidebar {
    background-color: #09ACE0;
  }
  
  .dark-theme {
    background-color: #C2C4C4;
    color: #FFFFFF;
  }
  
  .dark-theme .sidebar,
  .dark-theme .button {
    background-color: #363D3F;
  }
  
  .dark-theme .welcome-text,
  .dark-theme .input-label,
  .dark-theme .forgot-password,
  .dark-theme .input,
  .dark-theme .input::placeholder,
  .dark-theme .eye-icon {
    color: #363D3F;
  }
  
  .dark-theme .input {
    border-color: #FFFFFF;
  }


  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
  
    .sidebar {
      width: 100%;
      min-height: 100px;
    }
  
    .form-container {
      width: 100%;
      padding: 20px;
    }
  
    .input-label, .input, .button {
      font-size: 16px;
    }
  }