html,
body {
  min-height: 100vh;
  margin: 0;
  overflow: hidden;
}

.financial-reports-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 20px;
  flex: 1;
}

.top-section {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 20px;
}

.total-sales-card {
  margin-left: 20px;
}

.financial-report-card {
  width: 500px;
}

.total-sales-card,
.financial-report-card {
  padding: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-width: 200px;
  flex-shrink: 0;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
}

.reporting-section {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-left: 20px;
  overflow: hidden;
  flex: 1;
}

.table-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.table-wrapper {
  flex: 1;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.table-scroll {
  max-height: 250px;
  overflow-y: auto;
}

.appointments-table,
.receipts-table {
  width: 100%;
  border-collapse: collapse;
}

.appointments-table th,
.receipts-table th,
.appointments-table td,
.receipts-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.appointments-table th,
.receipts-table th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.time-period-selector-button {
  background-color: #09ace0;
  border: none;
  border-radius: 3px;
  font-size: 15px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 10px;
  margin-right: 2%;
}

.time-period-selector-button:hover {
  background-color: #2980b9;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.users-title {
  color: #757575;
}

.users-count {
  font-weight: bold;
}

.last-30-days {
  color: #757575;
  font-size: 14px;
}

.percentage-box {
  background-color: #e5f8e5;
  padding: 8px 16px;
  border-radius: 12px;
}

.percentage-text {
  color: green;
}

.financial-reports {
  flex: 1;
}

.no-results-message {
  padding: 1.5rem;
  text-align: center;
  color: #666;
  font-style: italic;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin: 1rem 0;
}

.dark .no-results-message {
  background-color: #2c3e50;
  color: #ecf0f1;
}