
.reset-password-container {
    display: flex;
    height: 100vh;
  }
  
  .reset-password-sidebar {
    width: 25%;
    background-color: #09ACE0; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .reset-password-logo {
    max-width: 80%;
  }
  
  .reset-password-form-container {
    width: 75%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .reset-password-center-logo {
    width: 80px;
    margin-bottom: 1rem;
  }
  
  .reset-password-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .reset-password-input-group {
    width: 100%;
    margin-bottom: 1rem;
  }
  
  .reset-password-input-label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    color: #666; 
  }
  
  .reset-password-input {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .reset-password-button {
    width: 100%;
    padding: 0.8rem;
    background-color: #09ACE0;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .reset-password-button:hover {
    background-color: #007bbf;
  }
  
  .reset-password-theme-toggle {
    margin-top: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .reset-password-error {
    color: red;
    margin-bottom: 1rem;
    font-size: 0.9rem;
  }
  
  .reset-password-success {
    color: green;
    margin-bottom: 1rem;
    font-size: 0.9rem;
  }
  

  .dark-theme {
    background-color: #333;
    color: white;
  }
  
  .dark-theme .reset-password-input {
    background-color: #555;
    color: white;
  }
  
  .dark-theme .reset-password-input-label {
    color: #ddd;
  }
  
  .dark-theme .reset-password-button {
    background-color: #09ACE0;
  }
  
  .dark-theme .reset-password-button:hover {
    background-color: #007bbf;
  }
  