.appointmentDetails__overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    z-index: 50;
    backdrop-filter: blur(4px);
  }
  
  .appointmentDetails__container {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 900px;
    max-height: 90vh;
    overflow: auto;
    transform: scale(0.95);
    opacity: 0;
    animation: modalEnter 0.3s ease forwards;
  }
  
  @keyframes modalEnter {
    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  .appointmentDetails__deleteModal {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 60;
    backdrop-filter: blur(4px);
  }
  
  .appointmentDetails__deleteContent {
    background-color: white;
    border-radius: 12px;
    padding: 1.5rem;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .appointmentDetails__deleteTitle {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1e293b;
    margin-bottom: 1rem;
  }
  
  .appointmentDetails__deleteOptions {
    display: grid;
    gap: 1.5rem;
    margin: 1.5rem 0;
  }
  
  .appointmentDetails__deleteOption {
    background-color: #f8fafc;
    padding: 1.25rem;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
  }
  
  .appointmentDetails__deleteOption h4 {
    font-size: 1rem;
    font-weight: 600;
    color: #1e293b;
    margin-bottom: 0.5rem;
  }
  
  .appointmentDetails__deleteOption p {
    color: #64748b;
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }
  
  .appointmentDetails__deleteWarning {
    color: #ef4444 !important;
  }
  
  .appointmentDetails__deleteActions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid #e2e8f0;
  }
  
  .appointmentDetails__error {
    margin-top: 1rem;
    padding: 0.75rem;
    background-color: #fee2e2;
    color: #ef4444;
    border-radius: 6px;
    font-size: 0.875rem;
  }
  
  .appointmentDetails__header {
    position: sticky;
    top: 0;
    background-color: white;
    padding: 1.5rem;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
  }
  
  .appointmentDetails__headerLeft {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .appointmentDetails__backButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #09ACE0;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    transition: all 0.2s;
    border-color: #09ACE0;
  }
  
  .appointmentDetails__backButton:hover {
    background-color: #e6f7ff;
  }
  
  .appointmentDetails__closeButton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    color: #64748b;
    border-color: #09ACE0;
  }
  
  .appointmentDetails__closeButton:hover {
    background-color: #f1f5f9;
    color: #0f172a;
  }
  
  .appointmentDetails__content {
    padding: 1.5rem;
  }
  
  .appointmentDetails__listContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .appointmentDetails__listControls {
    background-color: #f8fafc;
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .appointmentDetails__searchBar {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    gap: 0.5rem;
  }
  
  .appointmentDetails__searchInput {
    border: none;
    outline: none;
    width: 100%;
    font-size: 0.875rem;
    color: #1e293b;
  }
  
  .appointmentDetails__filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
  }
  
  .appointmentDetails__dateFilters {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  
  .appointmentDetails__filterLabel {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    color: #64748b;
  }
  
  .appointmentDetails__dateInput {
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
  }
  
  .appointmentDetails__statusFilter {
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    background-color: white;
    font-size: 0.875rem;
    color: #1e293b;
  }
  
  .appointmentDetails__sortButtons {
    display: flex;
    gap: 0.5rem;
  }
  
  .appointmentDetails__sortButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: white;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
    color: #64748b;
    transition: all 0.2s;
  }
  
  .appointmentDetails__sortButton:hover {
    background-color: #f1f5f9;
  }
  
  .appointmentDetails__sortButton.active {
    background-color: #e6f7ff;
    border-color: #09ACE0;
    color: #09ACE0;
  }
  
  .appointmentDetails__list {
    display: grid;
    gap: 1rem;
  }
  
  .appointmentDetails__listItem {
    border: 1px solid #e2e8f0;
    border-radius: 10px;
    background-color: white;
    transition: all 0.2s;
    overflow: hidden;
  }
  
  .appointmentDetails__listItemHeader {
    padding: 1rem;
    background-color: #f8fafc;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .appointmentDetails__listItemMain {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .appointmentDetails__listItemTime {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #09ACE0;
    font-size: 0.875rem;
  }
  
  .appointmentDetails__listItemDetails {
    padding: 1rem;
  }
  
  .appointmentDetails__listItemInfo {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
  }
  
  .appointmentDetails__infoColumn {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .appointmentDetails__infoLabel {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #64748b;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
  
  .appointmentDetails__infoValue {
    color: #1e293b;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .appointmentDetails__noResults {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 3rem;
    text-align: center;
    color: #64748b;
    background-color: white;
    border-radius: 10px;
    border: 2px dashed #e2e8f0;
  }
  
  .rotate-180 {
    transform: rotate(180deg);
  }

  .appointmentDetails__detailView {
  position: relative;
  padding: 1.5rem;
  background-color: white;
  border-radius: 12px;
}

.appointmentDetails__headerBar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
    border-bottom: 2px solid #e6f7ff;
  }

  .appointmentDetails__headerMain {
    flex: 1;
  }
  
  .appointmentDetails__headerTitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1e293b;
    margin-bottom: 0.5rem;
  }
  
  .appointmentDetails__headerTime {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #09ACE0;
    font-size: 1.1rem;
  }
  
  .appointmentDetails__headerStatus {
    margin-left: 1rem;
    padding: 0.5rem 1.5rem;
    border-radius: 8px;
    font-weight: 500;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .appointmentDetails__status--scheduled {
    background-color: #e0f2fe;
    color: #0284c7;
    border: 1px solid #7dd3fc;
  }
  
  .appointmentDetails__status--completed {
    background-color: #dcfce7;
    color: #16a34a;
    border: 1px solid #86efac;
  }
  
  .appointmentDetails__status--cancelled {
    background-color: #fee2e2;
    color: #dc2626;
    border: 1px solid #fca5a5;
  }
  
  .appointmentDetails__status--no-show {
    background-color: #fef2f2;
    color: #991b1b;
    border: 1px solid #fecaca;
  }
  
  .appointmentDetails__status--in-progress {
    background-color: #fef9c3;
    color: #ca8a04;
    border: 1px solid #fde047;
  }

  .appointmentDetails__quickActions {
    display: flex;
    gap: 1rem;
    padding: 1.5rem;
    background-color: #f8fafc;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    margin: 1rem 0 2rem 0;
    flex-wrap: wrap;
  }
  
  
  
  .appointmentDetails__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .appointmentDetails__section {
    background-color: #f8fafc;
    padding: 1.5rem;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    transition: all 0.2s ease;
  }
  
  .appointmentDetails__section:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transform: translateY(-2px);
  }
  
  .appointmentDetails__sectionTitle {
    font-size: 1.1rem;
    font-weight: 600;
    color: #09ACE0;
    margin-bottom: 1.2rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .appointmentDetails__infoGrid {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1rem 1.5rem;
    align-items: baseline;
  }
  
  .appointmentDetails__label {
    color: #64748b;
    font-weight: 500;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .appointmentDetails__value {
    color: #1e293b;
    font-weight: 500;
  }
  
  .appointmentDetails__description {
    background-color: #f8fafc;
    padding: 1.5rem;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    margin-top: 1.5rem;
  }
  
  .appointmentDetails__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }

  .appointmentDetails__descriptionContent {
    color: #475569;
    line-height: 1.6;
    white-space: pre-wrap;
  }
  
  .appointmentDetails__spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #09ACE0;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  .appointmentDetails__actions {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    padding-top: 1.5rem;
    border-top: 2px solid #e6f7ff;
  }
  
  .appointmentDetails__actionButton {
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.2s ease;
    border-color: transparent;
  }

  .appointmentDetails__input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
    color: #1e293b;
    background-color: white;
    transition: all 0.2s ease;
  }
  
  .appointmentDetails__input:focus {
    outline: none;
    border-color: #09ACE0;
    box-shadow: 0 0 0 2px rgba(9, 172, 224, 0.1);
  }

  .appointmentDetails__textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
    color: #1e293b;
    background-color: white;
    resize: vertical;
    min-height: 100px;
    transition: all 0.2s ease;
  }
  
  .appointmentDetails__textarea:focus {
    outline: none;
    border-color: #09ACE0;
    box-shadow: 0 0 0 2px rgba(9, 172, 224, 0.1);
  }

  .appointmentDetails__headerTitle input {
    font-size: 1.5rem;
    font-weight: 600;
    width: 100%;
    padding: 0.5rem;
    margin: -0.5rem;
  }
  
  .appointmentDetails__section input,
  .appointmentDetails__section select {
    width: 100%;
    padding: 0.5rem;
    margin: -0.5rem;
  }

  .appointmentDetails__section {
    transition: all 0.3s ease;
  }
  
  .appointmentDetails__section:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  }

  .appointmentDetails__quickStatusButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-weight: 500;
    font-size: 0.875rem;
    transition: all 0.2s ease;
    cursor: pointer;
    min-width: 140px;
    justify-content: center;
    background-color: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  .appointmentDetails__quickStatusButton[data-status="Completed"] {
    color: #16a34a;
    border: 1px solid #86efac;
  }
  
  .appointmentDetails__quickStatusButton[data-status="Completed"]:hover {
    background-color: #dcfce7;
    transform: translateY(-2px);
  }
  
  .appointmentDetails__quickStatusButton[data-status="In Progress"] {
    color: #ca8a04;
    border: 1px solid #fde047;
  }
  
  .appointmentDetails__quickStatusButton[data-status="In Progress"]:hover {
    background-color: #fef9c3;
    transform: translateY(-2px);
  }
  
  .appointmentDetails__quickStatusButton[data-status="No Show"] {
    color: #991b1b;
    border: 1px solid #fecaca;
  }
  
  .appointmentDetails__quickStatusButton[data-status="No Show"]:hover {
    background-color: #fef2f2;
    transform: translateY(-2px);
  }
  
  .appointmentDetails__quickStatusButton[data-status="Cancelled"] {
    color: #dc2626;
    border: 1px solid #fca5a5;
  }
  
  .appointmentDetails__quickStatusButton[data-status="Cancelled"]:hover {
    background-color: #fee2e2;
    transform: translateY(-2px);
  }

  .appointmentDetails__quickStatusButton.active {
    background-color: var(--status-bg);
    border-color: var(--status-color);
    color: var(--status-color);
    font-weight: 600;
  }
  
  .appointmentDetails__quickStatusButton:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
  }
  
  .appointmentDetails__quickStatus {
    background-color: #f8fafc;
    padding: 1.5rem;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    margin: 1.5rem 0;
    transition: all 0.2s ease;
  }

  .appointmentDetails__quickStatusButton.loading {
    position: relative;
    cursor: not-allowed;
  }
  
  .appointmentDetails__quickStatusButton.loading::after {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    border: 2px solid;
    border-radius: 50%;
    border-color: currentColor transparent currentColor transparent;
    animation: button-spin 0.8s linear infinite;
  }
  
  @keyframes button-spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  .appointmentDetails__quickStatus:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  }
  
  .appointmentDetails__statusButtons {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    flex-wrap: wrap;
  }
  
  .appointmentDetails__statusButton {
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.2s ease;
    border: 1px solid;
    background-color: transparent;
    cursor: pointer;
  }
  
  .appointmentDetails__statusButton:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .appointmentDetails__statusButton:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  .appointmentDetails__statusButton svg {
    transition: all 0.2s ease;
  }

  
  .appointmentDetails__actionButton--primary {
    background-color: #09ACE0;
    color: white;
  }
  
  .appointmentDetails__actionButton--primary:hover {
    background-color: #0789b4;
  }
  
  .appointmentDetails__actionButton--secondary {
    background-color: #e6f7ff;
    color: #09ACE0;
  }
  
  .appointmentDetails__actionButton--secondary:hover {
    background-color: #d1efff;
  }
  
  .appointmentDetails__actionButton--danger {
    background-color: #fee2e2;
    color: #ef4444;
  }
  
  .appointmentDetails__actionButton--danger:hover {
    background-color: #fecaca;
  }
  
  .appointmentDetails__status--scheduled {
    background-color: #e6f7ff;
    color: #0284c7;
  }
  
  .appointmentDetails__status--completed {
    background-color: #dcfce7;
    color: #16a34a;
  }
  
  .appointmentDetails__status--cancelled {
    background-color: #fee2e2;
    color: #ef4444;
  }
  
  .appointmentDetails__status--inProgress {
    background-color: #fef9c3;
    color: #ca8a04;
  }

  .dark .appointmentDetails__quickStatus {
    background-color: #1e293b;
    border-color: #334155;
  }
  
  .dark .appointmentDetails__statusButton {
    background-color: #334155;
    border-color: #475569;
    color: #f8f
  }
  
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  @media (max-width: 768px) {
    .appointmentDetails__grid {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  
    .appointmentDetails__container {
      margin: 0.5rem;
      max-height: 95vh;
    }
  
    .appointmentDetails__header {
      padding: 1rem;
    }
  
    .appointmentDetails__content {
      padding: 1rem;
    }
  
    .appointmentDetails__section {
      padding: 1rem;
    }
  }