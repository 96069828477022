.vaccine-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .vaccine-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .vaccine-modal-content h2 {
    margin-bottom: 20px;
    color: #09ace0;
    font-size: 1.5rem;
  }
  
  .vaccine-modal-content form label {
    display: block;
    margin-bottom: 15px;
    font-weight: 600;
    color: #34495e;
  }
  
  .vaccine-modal-content form input[type="text"],
  .vaccine-modal-content form input[type="date"] {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 6px;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  .vaccine-modal-content form input[type="text"]:focus,
  .vaccine-modal-content form input[type="date"]:focus {
    outline: none;
    border-color: #09ace0;
    box-shadow: 0 0 0 2px rgba(9, 172, 224, 0.2);
  }
  
  .vaccine-modal-error {
    color: red;
    font-size: 0.9rem;
    margin-bottom: 15px;
  }
  
  .vaccine-modal-submit {
    background-color: #09ace0;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    font-size: 1rem;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s ease;
  }
  
  .vaccine-modal-submit:hover {
    background-color: #2980b9;
  }
  
  .vaccine-modal-cancel {
    background-color: #FF4D4D;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 6px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .vaccine-modal-cancel:hover {
    background-color: #e04938;
  }
  