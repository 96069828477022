
.health-status-main {
  display: flex;
  padding: 20px;
  height: calc(100vh - 60px);
  width: 97.5%;
  margin-left: 22px;
}


.health-status-content {
  width: calc(100% - 270px);
  margin-left: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 50px;
  padding-right: 28px;
}

.health-status-header {
  margin-left: 0px;
  margin-bottom: 20px;
}

.health-status-section-box {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border: 1px solid #e6e6e6;
  margin-bottom: 20px;
}


.health-status-section-header {
  font-size: 1.4rem;
  margin-bottom: 15px;
  color: #09ace0;
}


.health-status-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
}

.health-status-info-item {
  background-color: #f8fafc;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  transition: all 0.3s ease;
}

.health-status-info-item:hover {
  background-color: #e6f7ff;
}

.health-status-info-label {
  font-weight: 600;
  color: #34495e;
  margin-bottom: 5px;
}

.health-status-info-value {
  color: #2c3e50;
}


.health-status-notes-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.health-status-textarea {
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 1rem;
  resize: vertical;
  min-height: 150px;
  transition: all 0.3s ease;
}

.health-status-textarea:focus {
  outline: none;
  border-color: #09ace0;
  box-shadow: 0 0 0 2px rgba(9, 172, 224, 0.2);
}


.health-status-action-button {
  background-color: #09ace0;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.health-status-action-button:hover {
  background-color: #2980b9;
}


.health-status-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.health-status-table th,
.health-status-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e6e6e6;
}

.health-status-table th {
  background-color: #f8fafc;
  font-weight: 600;
  color: #34495e;
}

.health-status-table tr:hover {
  background-color: #e6f7ff;
}

.health-status-no-data {
  text-align: center;
  font-style: italic;
  color: #757575;
}


@media (max-width: 768px) {
  .health-status-notes-grid {
    grid-template-columns: 1fr;
  }

  .health-status-info-grid {
    grid-template-columns: 1fr;
  }
}
