.payments-main {
  display: flex;
  padding: 20px;
  height: calc(100vh - 60px);
  width: 97.5%;
  margin-left: 22px;
}

.payments-page {
  width: calc(100% - 270px);
  margin-left: 20px;
  margin-right:20px;
  display: flex;
  flex-direction: row; 
  overflow-y: auto;
  padding-bottom: 50px;
}

.invoice-section {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 2rem;
  margin-right: 27px;
  max-height: 400px;
  overflow-y: auto;
  flex: 1; 
}

.invoice-header-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 15px;
}

.create-invoice-button {
  padding: 8px 16px;
  background-color: white;
  border: 1px solid #09ace0;
  border-radius: 5px;
  color: #09ace0;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
}

.create-invoice-button:hover {
  background-color: #09ace0;
  color: white;
}

.payment-method-section {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 2rem;
  max-height: 400px;
  overflow-y: auto;
  flex: 1;
}

.select-button {
  padding: 8px 12px;
  border: 1px solid #09ace0;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}

.select-button:hover {
  background-color: #09ace0;
  color: white;
}

.payment-options button {
  margin: 10px 10px 20px 10px;
  padding: 10px 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
}

.payment-pay-button {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin: 20px 0px 20px 10px;
  padding: 10px 20px;
  cursor: pointer;
}
.payment-pay-button:hover {
  background-color: #09ace0;
  color: white;
}

.accepted-cards-image {
  margin-top: 15px;
  width: 100%;
  max-width: 290px; 
  display: block;
}


.card-input-wrapper {
  border: 1px solid #d0d0d0;
  border-radius: 4px; 
  padding: 10px;
  background-color: #ffffff;
}


.card-input-wrapper:focus-within {
  border-color: #0a84ff; 
  box-shadow: 0 0 0 2px rgba(10, 132, 255, 0.2); 
}


.payment-options button:hover {
  background-color: #09ace0;
  color: white;
}

.payments-h2 {
  flex: 1;
  margin: 0;
}
