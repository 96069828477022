.medication-main {
  display: flex;
  padding: 20px;
  height: calc(100vh - 60px);
  width: 99%;
  margin-left: 22px;
}

.medication-page {
  width: calc(100% - 111px);
  margin-left: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 50px;
  padding-right: 50px;
}

.medication-section-box {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border: 1px solid #e6e6e6;
  margin-top: 20px;

}

.medication-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.no-results-message {
  padding: 1rem;
  text-align: center;
  color: #666;
  font-style: italic;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin: 1rem 0;
}

.search-medication {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  font-size: 1rem;
}

.medication-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
}

.medication-table th,
.medication-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #e6e6e6;
}

.medication-table th {
  background-color: #f8fafc;
  font-weight: 600;
  color: #34495e;
}

.medication-table tr:hover {
  background-color: #e6f7ff;
}

.loading-message {
  text-align: center;
  padding: 20px;
  font-size: 1.2rem;
  color: #7f8c8d;
}

.no-data-message {
  text-align: center;
  padding: 20px;
  font-size: 1.1rem;
  color: #7f8c8d;
  font-style: italic;
}
.medication-buttons {
  background-color: #09ace0;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.medication-buttons:hover {
  background-color: #2980b9;
}

.medication-header-controls {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

.no-results-message {
  padding: 1rem;
  text-align: center;
  color: #666;
  font-style: italic;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin: 1rem 0;
}

@media (max-width: 768px) {
  .medication-table {
    font-size: 0.9rem;
  }

  .PatientSidebar {
    display: none;
  }
}

.dark .no-results-message {
  background-color: #2c3e50;
  color: #ecf0f1;
}
