.contacts-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .new-contact-modal {
    width: 800px;
    max-width: 95%;
    background-color: white;
    border-radius: 12px;
    padding: 0;
    overflow: hidden;
    position: relative;
  }
  
  .modal-header {
    display: flex;
    align-items: center;
    padding: 25px;
    padding-right: 60px;
    border-bottom: 1px solid #e0e6ed;
    position: relative; 
  }
  
  .profile-upload-container {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    margin-right: 25px;
    flex-shrink: 0;
    background-color: #f8fafc;
  }
  
  .profile-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .profile-upload-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .profile-upload-overlay span {
    color: white;
    font-size: 0.8rem;
    margin-top: 5px;
  }
  
  .profile-upload-container:hover .profile-upload-overlay {
    opacity: 1;
  }
  
  .modal-title-section {
    flex: 1;
  }
  
  .modal-title-section h2 {
    font-size: 1.5rem;
    margin: 0 0 5px 0;
    color: #2c3e50;
  }
  
  .modal-subtitle {
    color: #7f8c8d;
    margin: 0;
    font-size: 0.9rem;
  }
  
  .new-contact-form {
    padding: 25px;
  }
  
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .form-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .input-group {
    display: flex;
    align-items: flex-start;
    gap: 15px;
  }
  
  .input-group svg {
    color: #09ACE0;
    margin-top: 12px;
  }
  
  .name-inputs {
    display: flex;
    gap: 10px;
    flex: 1;
  }
  
  .contact-input {
    flex: 1;
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid #e0e6ed;
    font-size: 0.95rem;
    transition: border-color 0.3s ease;
    background: none;
  }
  
  .contact-input:focus {
    outline: none;
    border-bottom-color: #09ACE0;
  }
  
  .contact-textarea {
    min-height: 80px;
    resize: vertical;
  }
  
  .full-width-inputs {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    padding-top: 20px;
    border-top: 1px solid #e0e6ed;
  }
  
  .modal-close-button {
    position: absolute;
    top: 50%;
    right: 25px; 
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
    padding: 8px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    z-index: 1;
  }
  
  
  .modal-close-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .cancel-button,
  .submit-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .cancel-button {
    background-color: #e74c3c;
    color: white;
  }
  
  .cancel-button:hover {
    background-color: #c0392b;
  }
  
  .submit-button {
    background-color: #09ACE0;
    color: white;
  }
  
  .submit-button:hover {
    background-color: #0789b4;
  }
  
  .modal-error-message {
    margin: 0 25px;
    margin-bottom: 15px;
    color: #e74c3c;
    background-color: #fde8e7;
    padding: 0.75rem;
    border-radius: 4px;
    text-align: center;
  }
  
  /* Dark mode support */
  .dark .new-contact-modal {
    background-color: #2c3e50;
    color: #ecf0f1;
  }
  
  .dark .modal-header {
    border-bottom-color: #34495e;
  }
  
  .dark .modal-title-section h2 {
    color: #ecf0f1;
  }
  
  .dark .modal-subtitle {
    color: #bdc3c7;
  }
  
  .dark .contact-input {
    color: #ecf0f1;
    border-bottom-color: #34495e;
  }
  
  .dark .contact-input:focus {
    border-bottom-color: #3498db;
  }
  
  .dark .modal-footer {
    border-top-color: #34495e;
  }
  
  .dark .modal-close-button {
    color: #ecf0f1;
  }
  
  .dark .modal-close-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  @media (max-width: 768px) {
    .form-grid {
      grid-template-columns: 1fr;
    }
  
    .name-inputs {
      flex-direction: column;
    }
  
    .profile-upload-container {
      width: 80px;
      height: 80px;
    }
  
    .modal-header {
      padding: 20px;
    }
  
    .new-contact-form {
      padding: 20px;
    }
  }