.end-of-day-content {
    padding: 20px;
  }
  
  .summary-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
  }
  
  .summary-card h6 {
    margin-bottom: 16px;
    color: #333;
    font-weight: 600;
  }
  
  .employee-status .MuiAvatar-root {
    width: 48px;
    height: 48px;
  }
  
  .employee-status .MuiTypography-h4 {
    margin-top: 8px;
    font-weight: 600;
  }
  
  .financial-summary .MuiBox-root {
    transition: transform 0.3s ease;
  }
  
  .financial-summary .MuiBox-root:hover {
    transform: translateY(-5px);
  }
  
  .financial-summary .MuiTypography-h5 {
    font-weight: 600;
  }

  .profit-breakdown .MuiGrid-container {
    height: 100%;
  }
  
  .profit-breakdown .financial-summary-item {
    padding: 16px;
    border-radius: 8px;
    text-align: center;
    transition: all 0.3s ease;
    margin-top: 16px;
  }
  
  .profit-breakdown .financial-summary-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .profit-breakdown .MuiTypography-subtitle1 {
    margin-bottom: 8px;
    font-weight: 500;
  }
  
  .profit-breakdown .MuiTypography-h4 {
    font-weight: 600;
  }
  
  .profit-breakdown .MuiSvgIcon-root {
    font-size: 1.5rem;
  }

  .comment-section {
    margin-top: 24px;
  }
  
  .comment-section .MuiTypography-h6 {
    margin-bottom: 16px;
    color: #333;
  }
  
  .comment-section .MuiTextField-root {
    margin-top: 8px;
  }
  
  .comment-section .MuiOutlinedInput-root {
    transition: all 0.3s ease;
  }
  
  .comment-section .MuiOutlinedInput-root:hover {
    border-color: #09ACE0;
  }
  
  .comment-section .MuiOutlinedInput-root.Mui-focused {
    border-color: #09ACE0;
  }
  
  .comment-section .MuiInputLabel-root.Mui-focused {
    color: #09ACE0;
  }

  @media (max-width: 600px) {
    .comment-section {
      padding: 16px;
    }
  }

  @media (max-width: 768px) {
    .end-of-day-content {
      padding: 10px;
    }
    
    .employee-status .MuiGrid-item {
      flex-basis: 33.33%;
      max-width: 33.33%;
    }
  }

  @media (max-width: 960px) {
    .profit-breakdown .MuiGrid-container {
      flex-direction: column;
    }
  
    .profit-breakdown .financial-summary-item {
      margin-bottom: 16px;
    }
  }