.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .read-only-estimate {
    background-color: #f0f0f0;  /* Light grey background */
    color: #666;                /* Dimmed text color */
    border: 1px solid #ddd;     /* Light border */
    cursor: not-allowed;        /* Change cursor to indicate non-editable */
}

  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 500px;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  }
  
  .modal-content h2 {
    margin-top: 0;
    font-size: 24px;
    font-weight: 600;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
    margin-right: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    color: #333;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
  }
  
  .form-group select {
    cursor: pointer;
  }
  
  .submit-button {
    background-color: #09ACE0;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
  }

  .submit-button:hover{
    background-color: #2980b9;
  }
  
  .close-button {
    background-color: #FF4D4D;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
  }
  .estimate-modal-button {
    display: flex;
  }

  .close-button:hover {
    background-color: #e04938;
    color: white;
  }
  
  .dark .modal-content {
    background-color: #363D3F;
    color: white;
  }
  
  .dark .form-group label {
    color: white;
  }
  
  .dark .form-group input,
  .dark .form-group select {
    background-color: #2a3132;
    border-color: #4a5256;
    color: white;
  }
  
  .dark .submit-button {
    background-color: #1a7a9e;
  }
  