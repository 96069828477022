/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal content */
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .modal-content h2 {
    margin-bottom: 15px;
    font-size: 20px;
  }
  
  .modal-content label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .modal-content input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .modal-actions button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-actions button:first-of-type {
    background-color: #09ACE0;
    color: white;
  }
  
  .modal-actions button:first-of-type:hover {
    background-color: #2980b9;
  }
  
  .modal-actions button:last-of-type {
    background-color: #FF4D4D;
    color: white;
  }
  
  .modal-actions button:last-of-type:hover {
    background-color: #e04938;
  }
  