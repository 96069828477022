.layout-container {
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  padding: 20px;
  gap: 20px;
}

.layout-sidebar {
  width: 300px;
  flex-shrink: 0;
  height: 100%;
  overflow-y: auto;
  margin-left: 22px;
  display: flex;
}

.layout-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: calc(100% - 320px);
}

.layout-main {
  flex: 1;
  overflow-y: auto;
}

.selector-list {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  height: fit-content;
}

.selector-header {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e6ed;
}

.selector-header h2 {
  font-size: 1.2rem;
  color: #333;
  margin: 0;
}

.selector-item {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.selector-item:hover {
  background-color: #eef2f7;
}

.selector-item.active {
  background-color: #09ace0;
  color: white;
}

.selector-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 1rem;
  object-fit: cover;
}

.selector-name {
  font-weight: 500;
  font-size: 0.9rem;
}

.selector-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 180px);
}

.selector-back {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  color: #09ACE0;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 10px;
  transition: all 0.2s ease;
}

.selector-back:hover {
  background-color: #f0f9ff;
  border-radius: 6px;
}

.patient-header {
  margin-bottom: 20px;
}

.layout-tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.layout-tab {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #e6e6e6;
  color: black;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.layout-tab:hover {
  background-color: #bfbfbf;
}

.layout-tab.active {
  background-color: #09ACE0;
  color: white;
}

@media (max-width: 768px) {
  .layout-sidebar {
    width: 250px;
  }
  
  .layout-content {
    max-width: calc(100% - 270px);
  }
}